import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { AppConfiguration } from "./app.configuration";
import { filter } from "rxjs/operators";
import { Router, NavigationEnd } from "@angular/router";
import { InitService } from "./core/init/init.service";
import { environment } from "src/environments/environment";

declare var gtag;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterContentChecked {
  proyecto = this.config.getProyecto();
  initValue;
  constructor(
    private router: Router,
    private config: AppConfiguration,
    private initService: InitService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.initService.started$.subscribe((val) => {
      this.initValue = val;
    });
    if (this.config.getProyecto() === "SNOWZONE") {
      this.initService.aceptaCookieGoolge$.subscribe((acepta) => {
        if (acepta) {
          const navEndEvents$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
          );
          navEndEvents$.subscribe((event: NavigationEnd) => {
            gtag("config", "UA-115524368-1", {
              page_path: event.urlAfterRedirects,
            });
          });
        }
      });
    } else {
      this.initService.aceptaCookieGoolge$.subscribe((acepta) => {
        if (acepta) {
          const navEndEvents$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
          );
          navEndEvents$.subscribe((event: NavigationEnd) => {
            gtag("config", this.config.getGoogleId(), {
              send_page_view: false,
            });
            gtag("config", this.config.getGoogleId(), {
              page_path: event.urlAfterRedirects,
            });
          });
        }
      });
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  esInicio() {
    return (
      this.router.url.startsWith("/producto") ||
      this.router.url.startsWith("/tienda/espectaculo") ||
      this.router.url === "/tienda/menu"
    );
  }
  estaVisibleEnRoot() {
    return (
      [
        "MAPFRE",
        "REALESCUELA",
        "FCATEDRALSANTIAGO",
        "ESTEPONAEULEN",
        "AYTO_ZGZ_FORO",
        "AYTO_ZGZ_TEATRO",
        "AYTO_ZGZ_PUERTO",
        "AYTO_ZGZ_TERMAS",
        "AYTO_ZGZ_GARG",
        "TICKAMORE_UNITARIO",
        "museocr",
        "FMCMP_Salamanca"
      ].indexOf(this.proyecto) === -1
    );
  }
}
