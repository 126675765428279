export const environment = {
  production: false,
  console: false,
  API_URL: "https://apipre.apiacpos.com/2_4_0_bridge/",
  numSerie: "WEBAN$A$l06$A$GULAR$A$AM3[jC)",
  chClaveEmpresa: "REALESCUELA",
  chNombreProyecto: "VENTAONLINE",
  chEntornoConexion: "PREPRODUCCION",
  chOrigenConexion: "INTERNET",
  chPasarelaEntorno: "PREPRODUCCION",
  pasarelaRedsys: "PREPRODUCCION",
  pasarelaPhoneSell: "PHONE_M_PRO",
  pasarelaEntornoPhoneSell: "CONSULTA_PRO",
  pasarelaPaypal: "PAYPAL_PRO",
  URL_PDF: "http://grecpruebas.iacpos.com/grec-api/tickets/",
  URLOK: "https://tickets.realescuela.iacpospre.com/thankyou",
  URLKO: "https://tickets.realescuela.iacpospre.com/thankko",
  URL: "https://tickets.realescuela.iacpospre.com/",
  siteKey: "6LfXS2IUAAAAADr2WUPQDzAnTEbSQzE1Jxh0Zi0a",
  timer: 20,
};
